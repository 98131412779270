import React, { useRef, useState, useEffect } from 'react';
import { getHash } from '~/utils';
import { globalStore } from '~/store';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
const WebGLData = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [webGLData, setWebGLData] = useState<any>(null);
  const [webGLHash, setWebGLHash] = useState<string | null>(null);
  const initWebGL = (canvas: HTMLCanvasElement) => {
    const gl = canvas.getContext('webgl');
    if (!gl) {
      console.error('Unable to initialize WebGL. Your browser may not support it.');
      return null;
    }

    const vertexShaderSource = `
      attribute vec2 a_position;
      attribute vec3 a_color;
      varying vec3 v_color;
      void main() {
        gl_Position = vec4(a_position, 0, 1);
        v_color = a_color;
      }
    `;

    const fragmentShaderSource = `
      precision mediump float;
      varying vec3 v_color;
      void main() {
        gl_FragColor = vec4(v_color, 1);
      }
    `;

    const createShader = (gl: WebGLRenderingContext, type: number, source: string): WebGLShader | null => {
      const shader = gl.createShader(type);
      if (!shader) {
        console.error('Error creating shader');
        return null;
      }
      gl.shaderSource(shader, source);
      gl.compileShader(shader);
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
      }
      return shader;
    };

    const createProgram = (gl: WebGLRenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader): WebGLProgram | null => {
      const program = gl.createProgram();
      if (!program) {
        console.error('Error creating program');
        return null;
      }
      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);
      gl.linkProgram(program);
      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error('Unable to initialize the shader program: ' + gl.getProgramInfoLog(program));
        return null;
      }
      return program;
    };

    const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
    const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);

    if (!vertexShader || !fragmentShader) {
      return null;
    }

    const program = createProgram(gl, vertexShader, fragmentShader);
    if (!program) {
      return null;
    }

    const positionBuffer = gl.createBuffer();
    if (!positionBuffer) {
      console.error('Error creating position buffer');
      return null;
    }
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = new Float32Array([0, 1, -1, -1, 1, -1]);
    gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

    const colorBuffer = gl.createBuffer();
    if (!colorBuffer) {
      console.error('Error creating color buffer');
      return null;
    }
    gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
    const colors = new Float32Array([1, 0, 0, 0, 1, 0, 0, 0, 1]);
    gl.bufferData(gl.ARRAY_BUFFER, colors, gl.STATIC_DRAW);

    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.useProgram(program);

    const positionLocation = gl.getAttribLocation(program, 'a_position');
    if (positionLocation === -1) {
      console.error('Error finding position location');
      return null;
    }
    gl.enableVertexAttribArray(positionLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    const colorLocation = gl.getAttribLocation(program, 'a_color');
    if (colorLocation === -1) {
      console.error('Error finding color location');
      return null;
    }
    gl.enableVertexAttribArray(colorLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
    gl.vertexAttribPointer(colorLocation, 3, gl.FLOAT, false, 0, 0);

    gl.drawArrays(gl.TRIANGLES, 0, 3);

    const imageData = new Uint8Array(gl.drawingBufferWidth * gl.drawingBufferHeight * 4);
    gl.readPixels(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight, gl.RGBA, gl.UNSIGNED_BYTE, imageData);

    return { gl, imageData };
  };

  const getWebGLParams = (gl: WebGLRenderingContext) => {
    const params: Record<string, any> = {};

    const paramNames = Object.getOwnPropertyNames(WebGLRenderingContext.prototype).filter(
      (name) =>
        name.startsWith('MAX_') ||
        name.startsWith('ALI_') ||
        name.startsWith('DEP_') ||
        name.startsWith('SHA_') ||
        name.startsWith('STEN_') ||
        name.startsWith('SUB_') ||
        name.startsWith('UNA_') ||
        name.startsWith('VEN_') ||
        name.startsWith('VER_'),
    );

    paramNames.forEach((paramName) => {
      try {
        const param = gl.getParameter((gl as any)[paramName]);
        params[paramName] = param !== null ? param : 'N/A';
      } catch (e) {
        params[paramName] = 'N/A';
      }
    });

    const precisionFormats = ['HIGH_FLOAT', 'HIGH_INT', 'LOW_FLOAT', 'MEDIUM_FLOAT'];
    const shaderTypes = [gl.FRAGMENT_SHADER, gl.VERTEX_SHADER];

    shaderTypes.forEach((shaderType) => {
      precisionFormats.forEach((precisionFormat) => {
        const format = gl.getShaderPrecisionFormat(shaderType, (gl as any)[precisionFormat]);
        params[`${shaderType === gl.FRAGMENT_SHADER ? 'FRAGMENT_SHADER' : 'VERTEX_SHADER'}.${precisionFormat}.precision`] = format?.precision;
        params[`${shaderType === gl.FRAGMENT_SHADER ? 'FRAGMENT_SHADER' : 'VERTEX_SHADER'}.${precisionFormat}.rangeMax`] = format?.rangeMax;
        params[`${shaderType === gl.FRAGMENT_SHADER ? 'FRAGMENT_SHADER' : 'VERTEX_SHADER'}.${precisionFormat}.rangeMin`] = format?.rangeMin;
      });
    });

    return params;
  };

  const getWebGLExtensions = (gl: WebGLRenderingContext) => {
    return gl.getSupportedExtensions() || [];
  };

  useEffect(() => {
    const fetchWebGLData = async () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const webGLData = initWebGL(canvas);
        if (webGLData) {
          const { gl, imageData } = webGLData;

          const hashImage = await getHash(imageData);
          const params = getWebGLParams(gl);
          const extensions = getWebGLExtensions(gl);

          const metaData = {
            gpu: gl.getParameter(gl.RENDERER) || 'N/A',
            vendor: gl.getParameter(gl.VENDOR) || 'N/A',
            version: gl.getParameter(gl.VERSION) || 'N/A',
            shadingLanguageVersion: gl.getParameter(gl.SHADING_LANGUAGE_VERSION) || 'N/A',
            antialias: gl.getContextAttributes()?.antialias || 'N/A',
            extensions,
            params,
          };

          const hashMeta = await getHash(JSON.stringify(metaData));
          const fullHash = await getHash(hashImage + hashMeta);

          const data = { ...metaData };
          globalStore.set({ ...globalStore.get(), webgl: { hash: fullHash, data: data } });
          setWebGLHash(fullHash);
          setWebGLData(data);
        }
      }
    };

    fetchWebGLData();
  }, []);

  return (
    <div>
      <div>
        <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">WebGL <span className="text-sm border px-1">{webGLHash?.slice(0, 12)}</span></h2>
        <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
          <canvas ref={canvasRef} width={200} height={100} className="mb-5" />
          <JsonView src={webGLData} />
        </div>
      </div>
    </div>
  );
};

export default WebGLData;
